import dayjs from 'dayjs'
import { map } from 'rxjs'
import i18n from '../../i18n'
import { updateUser } from '../../hooks/useUser.jsx'
import { getDateString } from '../../utils/date'
import { getRandomNumber } from '../../utils/math'
import { getApiHost } from '../../utils/site'
import api from '../index.js'
import {
  client_id,
  getEndpointData,
  getEndpointDataRow,
  setEndpointData
} from './index.js'

const screenshot = '/dont-eat-me.webp'

const exampleDomains = [
  'demoexample.com',
  'demoprovider.com',
  'demoproviderexample.com',
  'exampledemo.com',
  'exampleexample.com',
  'exampleprovider.com',
  'providerdemo.com',
  'providerexample.com'
]

const getDelivered = (r) => {
  let nb_send = r.nb_send

  if (nb_send < r.nb_hardbounce + r.nb_softbounce + r.nb_otherbounce) {
    nb_send = r.nb_send + r.nb_hardbounce + r.nb_softbounce + r.nb_otherbounce
  }

  const nb_delivered =
    nb_send - r.nb_hardbounce - r.nb_softbounce - r.nb_otherbounce

  return nb_delivered
}

const getUserTokenPayload = () =>
  JSON.parse(atob(localStorage.loginToken.split('.')[1]))

const getUser = () => {
  const { sub } = getUserTokenPayload()

  return {
    created_at: '',
    display_name: `${sub.firstname} ${sub.lastname}`,
    id: sub.id,
    last_action: '',
    last_login: '',
    status: 0,
    type: sub.administrator ? 'admin' : 'user'
  }
}

const progressCampaign = (c) => {
  const progress = getRandomNumber(100, 300)
  const remainder = c.estimated_volume - c.progress_cur
  if (progress > remainder) {
    c.progress_cur = c.estimated_volume
    c.valid = 3
  } else {
    c.progress_cur += progress
  }
}

const refreshDates = (rows, keys) => {
  const oldest = dayjs('1900-01-01')

  const latest = Object.fromEntries(
    keys.map((key) => [
      key,
      rows.reduce((acc, row) => {
        if (row[key] === undefined) return acc
        const d = dayjs(row[key])
        return d.isAfter(acc) ? d : acc
      }, oldest)
    ])
  )

  const now = dayjs()
  for (const row of rows) {
    for (const key of keys) {
      if (['', null, undefined].includes(row[key]) || latest[key] === oldest) {
        continue
      }
      const d = dayjs(row[key])
      const diff = latest[key].diff(d)
      row[key] = now.subtract(diff, 'ms').toISOString()
    }
  }

  return rows
}

/**
 * alias: 'serviceName' // (optional)
 * Use name and config of serviceName. Used do avoid v1/v2 service duplication
 *
 * generateEntry: (...args) => entry // (optional)
 * Generates a service entry, mostly used by getStaticData()
 *
 * getDefaults: async (payload) => entry // (optional)
 * Receives the body of a create$/post request, adds default values to it
 *
 * getStaticData: async (Request) => {} // (optional)
 * Is called to retrieve data (instead of actual api endpoint)
 *
 * includes: ['string', 'string', …] // (optional)
 * Relations to always include via jsonapi when fetching data
 *
 * key: 'string' // (required)
 * Key to retrieve the ID with
 *
 * regex: RegExp // (required)
 * Regular Expression Object to match url with
 *
 * skipParams: bool // (optional)
 * skips setting any query params to index calls when retrieving data (like
 * offset, limit)
 *
 * transformIndex: async (rows, id, URL) => rows // (optional)
 * Transform index calls retrieved from indexeddb before it's submitted to app
 *
 * transformRow: async (rows, id, URL) => rows // (optional)
 * Transform row data retrieved from indexeddb before it's submitted to app
 *
 * transformRows: async (rows, id, URL) => rows // (optional)
 * Transform paginated data retrieved from indexeddb before it's submitted to
 * app
 *
 * update: (rows) => rows // (optional)
 * Transform data before it's stored in indexeddb (refresh timestamps etc.)
 */

const mockedServices = {
  dashboards: {
    getDefaults: () => {
      throw new Error(i18n?.t('errors.not_supported_in_demo'))
    },
    getStaticData: () => [
      {
        client_id,
        content: JSON.stringify([
          {
            id: crypto.randomUUID(),
            name: 'campaignsResponse',
            settings: {
              name: 'Newsletters',
              global: false,
              filters: {
                domain_id: [],
                mailclass_id: [],
                member_table_id: [],
                category_value_id: []
              },
              metrics: {
                nb_open: true,
                nb_send: true,
                nb_click: true,
                rate_open: true,
                rate_click: true,
                nb_campaigns: false,
                nb_complaint: false,
                nb_delivered: true,
                nb_hardbounce: false,
                nb_softbounce: false,
                nb_otherbounce: false,
                nb_send_queued: true,
                nb_unsubscribe: true,
                rate_allbounce: false,
                rate_complaint: false,
                rate_delivered: true,
                rate_hardbounce: false,
                rate_softbounce: false,
                rate_otherbounce: false,
                rate_unsubscribe: false,
                rate_click_to_open: true,
                nb_campaigns_queued: false,
                rate_complaint_to_open: false,
                rate_unsubscribe_to_open: false
              },
              end_date: dayjs().format('YYYY-MM-DD'),
              dyn_range: 'current_month',
              precision: 'day',
              searchterm: '',
              show_rates: true,
              start_date: `${dayjs().format('YYYY')}-01-01`,
              enable_slices: true,
              highlight_weekends: false
            }
          },
          {
            id: crypto.randomUUID(),
            name: 'subscribers30d'
          },
          { id: crypto.randomUUID(), name: 'sendvolume' },
          {
            id: crypto.randomUUID(),
            name: 'accountOverview'
          },
          { id: crypto.randomUUID(), name: 'subscribers' }
        ]),
        id: 1,
        is_public: false,
        name: i18n.t('dashboard.default_name')
      }
    ],
    key: 'id',
    regex: /^\/dashboard/
  },
  blacklistsglobal: {
    key: 'blacklist_id',
    regex: /^\/emma\/blacklist/,
    update: (rows) => refreshDates(rows, ['created_at'])
  },
  campaignaction_pause: {
    getStaticData: () => [],
    key: 'campagne_id',
    regex: /^\/emma\/campaign\/(\d+)\/pause/,
    transformRow: async (_row, id) => {
      const serviceName = 'campaignsv2'
      const serviceConfig = mockedServices[serviceName]

      const c = await getEndpointDataRow(serviceName, id)
      c.valid = 5
      await setEndpointData(serviceName, serviceConfig, [c])

      return c
    }
  },
  campaignaction_start: {
    getStaticData: () => [],
    key: 'campagne_id',
    regex: /^\/emma\/campaign\/(\d+)\/start/,
    transformRow: async (_row, id) => {
      const serviceName = 'campaignsv2'
      const serviceConfig = mockedServices[serviceName]

      const c = await getEndpointDataRow(serviceName, id)
      c.valid = 2
      await setEndpointData(serviceName, serviceConfig, [c])

      return c
    }
  },
  campaignaction_stats: {
    generateEntry: (campagne_id) => [
      {
        aggregated: {
          smtp_server_stats: Object.fromEntries(
            exampleDomains.map((domain) => {
              const new_msg = getRandomNumber(800, 1200)
              return [
                domain,
                {
                  domain,
                  new_msg,
                  accepted: new_msg,
                  deferred: 0,
                  failed: 0,
                  expired: 0,
                  deferred_throttle: 0
                }
              ]
            })
          )
        },
        campagne_id
      }
    ],
    getStaticData: (r) => {
      const url = new URL(r.url)
      const serviceConfig = mockedServices.campaignaction_stats
      const match = url.pathname.match(serviceConfig.regex)
      return serviceConfig.generateEntry(Number(match[1]))
    },
    key: 'campagne_id',
    regex: /^\/emma\/campaign\/(\d+)\/stats/,
    transformRow: async (data, id) => {
      const serviceName = 'campaignaction_stats'
      const serviceConfig = mockedServices[serviceName]
      if (!data && id) {
        data = await serviceConfig.getStaticData({
          url: `http://domain/emma/campaign/${id}/stats`
        })
        await setEndpointData(serviceName, serviceConfig, data)
      }
      return data
    }
  },
  campaignaction_stop: {
    getStaticData: () => [],
    key: 'campagne_id',
    regex: /^\/emma\/campaign\/(\d+)\/stop/,
    transformRow: async (_row, id) => {
      const serviceName = 'campaignsv2'
      const serviceConfig = mockedServices[serviceName]

      const c = await getEndpointDataRow(serviceName, id)
      c.valid = 8
      await setEndpointData(serviceName, serviceConfig, [c])

      return c
    }
  },
  campaignaction_teststats: {
    generateEntry: (email, timestamp) => {
      const [localpart, domain] = email.split('@')
      return {
        head: '',
        html: `\n<p>Showing data for: </p>\n\n\n\n<table class=\"quick_data\">\n\n<tr class=\"lb\" width=\"100%\">\n<td><b>injection_time</b></td>\n<td><b>localpart</b></td>\n<td><b>domain</b></td>\n<td><b>smtp_result_code</b></td>\n<td><b>smtp_result_time</b></td>\n<td><b>status_messages</b></td>\n<td><b>outmtaid</b></td>\n</tr>\n\n<tr class=\"lb\">\n<td nowrap>${timestamp}</td>\n<td>${localpart}</td>\n<td>${domain}</td>\n<td>accepted</td>\n<td nowrap>${timestamp}</td>\n<td></td>\n<td>smtp2-20_testmail_mc</td>\n</tr>\n</table>\n`,
        success: 1
      }
    },
    getStaticData: async (r) => {
      const url = new URL(r.url)
      const serviceConfig = mockedServices.campaignaction_teststats
      const match = url.pathname.match(serviceConfig.regex)
      const timestamp = dayjs()
        .subtract(1, 'hour')
        .format('YYYY-MM-DD HH:mm:ss')
      const [{ email }] = await getEndpointData('testsubscribers')
      return {
        campagne_id: Number(match[1]),
        rows: [serviceConfig.generateEntry(email, timestamp)]
      }
    },
    key: 'campagne_id',
    regex: /^\/emma\/campaign\/(\d+)\/teststats/
  },
  datasources: {
    key: 'member_table_id',
    update: async (rows) => {
      for (const row of rows) {
        if (!row) continue

        if (!row.emmamembertableschema?.[0]?.schema) {
          const { included } = await window
            .windowFetch(
              `https://${getApiHost()}/emma/membertable/${row.member_table_id}?explicitly_included%5B%5D=emmamembertableschema`,
              {
                headers: {
                  accept: 'application/vnd.api+json',
                  authorization: `Bearer ${localStorage.loginToken}`
                }
              }
            )
            .then((res) => res.json())
          row.emmamembertableschema = [included[0].data]
        }
      }
      return rows
    },
    regex: /^\/emma\/membertable/,
    transformIndex: async (rows) => {
      const subscribers = await getEndpointData('subscribers')
      const stats_active = subscribers.filter((s) => !s.dateunjoin).length
      const stats_unsubscribed = subscribers.filter((s) => s.dateunjoin).length
      const stats_bounced = Math.ceil(stats_active * 0.0005)
      const total_openers = Math.ceil(
        stats_active * (getRandomNumber(200, 300) / 1000)
      )
      const total_clickers = Math.ceil(
        stats_active * (getRandomNumber(200, 300) / 1000)
      )

      const datasources = rows.map((row = {}) => ({
        ...row,
        clickers_30d: total_clickers,
        never_clicked: stats_active - total_clickers,
        never_opened: stats_active - total_openers,
        openers_30d: total_openers,
        stats_active,
        stats_blacklisted: 0,
        stats_blacklisted_30d: 0,
        stats_bounced,
        stats_bounced_30d: stats_bounced,
        stats_complained: 0,
        stats_complained_30d: 0,
        stats_lost: 0,
        stats_new: stats_active,
        stats_refresh: getDateString(),
        stats_refresh_status: '',
        stats_total: stats_active,
        stats_unsubscribed,
        stats_unsubscribed_30d: stats_unsubscribed,
        total_clickers,
        total_openers
      }))
      updateUser({ datasources })
      return datasources
    }
  },
  reportsdownload: {
    getStaticData: () => [],
    key: 'campagne_id',
    regex: /^\/emma\/report\/download/,
    transformIndex: async () => {
      throw new Error(i18n?.t('errors.not_supported_in_demo'))
    }
  },
  salesview: {
    getStaticData: () => [],
    key: 'campagne_id',
    regex: /^\/emma\/report\/(\d+)\/salesview/,
    transformRow: async () => {
      throw new Error(i18n?.t('errors.not_supported_in_demo'))
    }
  },
  reports: {
    generateEntry: (c, nb_blacklist) => {
      const {
        campagne_id,
        client_id,
        domain,
        list = [],
        mailclass = [],
        message,
        name,
        screenshot,
        startdate
      } = c

      const nb_send = c.valid === 2 ? c.progress_cur : c.estimated_volume
      const nb_open = Math.ceil(nb_send * (getRandomNumber(200, 300) / 1000))
      const nb_click = Math.ceil(nb_open * (getRandomNumber(200, 300) / 1000))

      const daysRunning = dayjs().diff(dayjs(startdate), 'day')
      const opens_clicks_over_time = JSON.stringify(
        Object.fromEntries(
          Array(daysRunning)
            .fill(null)
            .map((_, i) => [
              dayjs(startdate).add(i, 'day').format('YYYY-MM-DD'),
              {
                opens: Math.ceil((nb_open * 0.85) ** (1 - i / 10)),
                clicks: Math.ceil((nb_click * 0.85) ** (1 - i / 10))
              }
            ])
        )
      )

      return {
        campagne_id,
        campagne_name: name,
        campaign: [c],
        client_id,
        domain,
        id: campagne_id,
        last_time_archived: startdate,
        list,
        mailclass,
        manager_id: JSON.parse(atob(localStorage.loginToken.split('.')[1])).sub
          .id,
        message,
        nb_blacklist,
        nb_click,
        nb_click_desktop: Math.ceil(nb_click * 0.6),
        nb_click_mobile: Math.ceil(nb_click * 0.33),
        nb_complaint: getRandomNumber(0, 10),
        nb_conversion: 0,
        nb_duplicate: 0,
        nb_hardbounce: Math.ceil(nb_send * 0.0005),
        nb_open,
        nb_open_desktop: Math.ceil(nb_open * 0.6),
        nb_open_mobile: Math.ceil(nb_open * 0.33),
        nb_open_total: Math.ceil(nb_open * 1.1),
        nb_otherbounce: Math.ceil(nb_send * 0.005),
        nb_preblock: Math.ceil(nb_send * 0.00075),
        nb_proxy_open: Math.ceil(nb_open * 0.4),
        nb_quality_rejected: 0,
        nb_send,
        nb_softbounce: Math.ceil(nb_send * 0.001),
        nb_total_click: Math.ceil(nb_click * 1.1),
        nb_unsubscribe: Math.ceil(nb_send * 0.00005),
        nb_unsubscribe_customer: 0,
        opens_clicks_over_time,
        provider: [],
        screenshot: screenshot[0],
        startdate,
        sub_report: 0,
        _rev: crypto.randomUUID()
      }
    },
    getStaticData: async () => {
      const [
        campaigns,
        { total: numBlacklistsGlobal },
        { data: blacklistsCampaign }
      ] = await Promise.all([
        getEndpointData('campaignsv2'),
        api.blacklistsglobal.find$({ limit: 0 }).toPromise(),
        api.blacklistscampaign.findAll$().toPromise()
      ])

      return campaigns
        .filter((c) => c.ctype === 1 && c.valid > 1)
        .map((c) => {
          const tags = Array.isArray(c.blacklist_source)
            ? c.blacklist_source
            : [c.blacklist_source]

          const numBlacklistsCampaign = blacklistsCampaign
            .filter((b) => tags.includes(b.flag))
            .reduce((acc, b) => acc + b.nb_uploaded, 0)

          return mockedServices.reports.generateEntry(
            c,
            numBlacklistsCampaign + numBlacklistsGlobal
          )
        })
    },
    transformRows: async (rows) => {
      const serviceName = 'campaignsv2'

      const campaigns = await Promise.all(
        rows
          .filter((r) => r.campaign[0].valid === 2)
          .map((r) => getEndpointDataRow(serviceName, r.campagne_id))
      )

      return rows.map((r) => {
        if (r.campaign[0].valid !== 2) return r

        const c = campaigns.find((c) => c.campagne_id === r.campagne_id)
        progressCampaign(c)
        r.campaign[0] = c
        setEndpointData(serviceName, mockedServices[serviceName], [c])
        return mockedServices.reports.generateEntry(c, r.nb_blacklist)
      })
    },
    key: 'campagne_id',
    regex: /^\/emma\/report/
  },
  campaigns: {
    alias: 'campaignsv2',
    regex: /^\/emma\/campaign/
  },
  clickreport: {
    getStaticData: async () => {
      const [reports, templates] = await Promise.all([
        getEndpointData('reports'),
        getEndpointData('templates').catch(() =>
          api.templates
            .findAll$()
            .pipe(map((r) => r.data))
            .toPromise()
        )
      ])

      return reports
        .filter((r) => r.campaign[0].ctype === 1 && r.campaign[0].valid > 1)
        .map((r) => {
          const template = templates.find(
            (t) => t.message_id === r.campaign[0].message_id
          )
          return {
            campagne_id: r.campagne_id,
            rows:
              template.url
                ?.toSorted((a, b) => a.url_id - b.url_id)
                .map((u, i) => ({
                  nb_click_total: Math.ceil(
                    (r.nb_click * 0.85) ** (1 - i / 10)
                  ),
                  ordre: i + 1,
                  url: u.url
                })) || []
          }
        })
    },
    key: 'campagne_id',
    regex: /^\/v2\/emma\/client\/\d+\/campaign\/(\d+)\/click-report/,
    transformRow: (rows) => rows.rows ?? rows
  },
  cmuhistory: {
    getStaticData: async () => {
      const [reports, templates] = await Promise.all([
        getEndpointData('reports'),
        getEndpointData('templates').catch(() =>
          api.templates
            .findAll$()
            .pipe(map((r) => r.data))
            .toPromise()
        )
      ])

      return reports
        .filter((r) => r.campaign[0].ctype === 1 && r.campaign[0].valid > 1)
        .map((r) => {
          const template = templates.find(
            (t) => t.message_id === r.campaign[0].message_id
          )
          return {
            body_long: template.body_long,
            body_text: template.body_text,
            campagne_id: r.campagne_id,
            startcounter: 0,
            startdate: r.campaign[0].startdate,
            subject: template.subject,
            url_ids: template.url?.map((u) => u.url_id) || [],
            urls:
              template.url?.map((u, i) => ({
                ...u,
                nb_click_total: Math.ceil((r.nb_click * 0.85) ** (1 - i / 10))
              })) || []
          }
        })
    },
    key: 'campagne_id',
    regex: /^\/v2\/emma\/client\/\d+\/campaign\/(\d+)\/c-m-u-history/
  },
  mtareport: {
    getStaticData: (r) => {
      const url = new URL(r.url)
      const match = url.pathname.match(mockedServices.mtareport.regex)
      const row = mockedServices.campaignaction_stats.generateEntry(
        Number(match[1])
      )
      return row
    },
    key: 'campagne_id',
    regex: /^\/v2\/emma\/client\/\d+\/campaign\/(\d+)\/mta-report/,
    transformRow: async (data, id) => {
      const serviceName = 'campaignaction_stats'
      const serviceConfig = mockedServices[serviceName]
      if (!data && id) {
        data = await serviceConfig.getStaticData({
          url: `http://domain/emma/campaign/${id}/stats`
        })
        await setEndpointData(serviceName, serviceConfig, data)
      }
      return data
    }
  },
  reportsview: {
    generateEntry: (startdate) => {
      const nb_campaigns = Math.ceil(Math.random() * 10)
      const nb_send = Math.ceil(nb_campaigns * 10)
      const nb_delivered = Math.ceil(nb_send * 0.98)
      const nb_open = Math.ceil(nb_delivered * 0.9)
      return {
        nb_campaigns,
        nb_click: Math.ceil(nb_open * 0.8),
        nb_complaint: Math.ceil(nb_delivered * 0.05),
        nb_delivered,
        nb_hardbounce: Math.ceil((nb_send - nb_delivered) / 4),
        nb_open,
        nb_otherbounce: Math.ceil((nb_send - nb_delivered) / 4),
        nb_send,
        nb_softbounce: Math.ceil((nb_send - nb_delivered) / 2),
        nb_unsubscribe: Math.ceil(nb_delivered * 0.05),
        startdate
      }
    },
    getStaticData: (r) => {
      const serviceConfig = mockedServices.reportsview
      const url = new URL(r.url)

      const endDate = dayjs(url.searchParams.get('enddate') ?? undefined)
      const match = url.pathname.match(serviceConfig.regex)
      const campagne_id = Number(match[1])

      let startDate = url.searchParams.has('startdate')
        ? dayjs(url.searchParams.get('startdate'))
        : endDate.subtract(7, 'day')

      const rows = []
      while (!startDate.isAfter(endDate)) {
        rows.push(serviceConfig.generateEntry(startDate.format('YYYY-MM-DD')))
        startDate = startDate.add(1, 'day')
      }

      return { campagne_id, rows }
    },
    key: 'campagne_id',
    regex:
      /^\/v2\/emma\/client\/\d+\/membertable\/\d+\/campaign\/(\d+)\/report:view/,
    transformRow: async (rows = [], _id, url) => {
      const endDate = dayjs(url.searchParams.get('enddate') ?? undefined)
      let startDate = url.searchParams.has('startdate')
        ? dayjs(url.searchParams.get('startdate'))
        : endDate.subtract(7, 'day')

      const data = []
      const newRows = []

      const serviceName = 'reportsview'
      const serviceConfig = mockedServices[serviceName]

      while (!startDate.isAfter(endDate)) {
        const d = startDate.format('YYYY-MM-DD')

        let row = (rows.rows ?? rows).find((r) => r.startdate === d)
        if (!row) {
          row = serviceConfig.generateEntry(d)
          newRows.push(row)
        }

        data.push(row)
        startDate = startDate.add(1, 'day')
      }

      if (newRows.length > 0) {
        const match = url.pathname.match(serviceConfig.regex)
        const campagne_id = Number(match[1])
        await setEndpointData(serviceName, serviceConfig, [
          {
            campagne_id,
            rows: [...rows, ...newRows]
          }
        ])
      }

      return data
    }
  },
  campaignsv2: {
    getDefaults: async (payload) => {
      const c = {
        client_id,
        combined_last_count: getDateString(),
        manager_id: JSON.parse(atob(localStorage.loginToken.split('.')[1])).sub
          .id,
        member_table_id: 1,
        screenshot: [{ url_large: screenshot }],
        ...payload
      }

      if (c.ctype === 1) {
        c.combined_count_status = 2 // int<2 = still_counting
      }

      const { data: list } = await api.segments
        .findAll$({ filters: { list_id: c.list_id } })
        .toPromise()
      c.list = list

      const volume = getRandomNumber(250_000, 300_000)
      c.combined_volume = volume
      c.estimated_volume = volume

      const domain = await api.domains.findOne$(c.domain_id).toPromise()
      domain.id = domain.domain_id
      c.domain = [domain]

      const mailclass = await api.mailclasses
        .findOne$(c.mailclass_id)
        .toPromise()
      c.mailclass = [mailclass]

      const message = await api.templates.findOne$(c.message_id).toPromise()
      c.message = [message]

      return c
    },
    includes: ['domain', 'list', 'mailclass', 'message', 'screenshot'],
    key: 'campagne_id',
    regex: /^\/v2\/emma\/client\/\d+\/campaign/,
    transformRows: async (rows) => {
      for (const c of rows) {
        if (c.valid !== 2) continue
        progressCampaign(c)
      }

      return rows
    },
    update: (rows) => {
      const ctypes = rows.reduce((acc, c) => {
        if (!acc[c.ctype]) {
          acc[c.ctype] = []
        }
        acc[c.ctype].push(c)
        return acc
      }, {})

      const data = []
      for (const [ctype, campaigns] of Object.entries(ctypes)) {
        refreshDates(campaigns, ['combined_last_count', 'startdate'])
        campaigns.sort((a, b) => new Date(b.startdate) - new Date(a.startdate))

        if (ctype === '1') {
          for (let i = 0; i < campaigns.length; i++) {
            const volume = getRandomNumber(250_000, 300_000)

            if (i === 0) {
              campaigns[i].valid = 2
              campaigns[i].progress_cur = Math.ceil(volume / 100)
            }
            if ([1, 2, 3].includes(i)) {
              campaigns[i].valid = 3
            }

            campaigns[i].estimated_volume = volume
            campaigns[i].combined_volume = volume
          }
        }

        data.push(...campaigns)
      }

      return rows
    }
  },
  motd: {
    key: 'id',
    regex: /^\/motd/,
    update: (rows) => rows.filter((row) => row.id === 143)
  },
  placeholders: {
    getDefaults: () => ({ is_public: false }),
    key: 'module_id',
    regex: /^\/emma\/placeholder/
  },
  reportinggroup: {
    getDefaults: () => ({ client_id, member_table_id: 1 }),
    key: 'id',
    regex: /^\/reportinggroup/
  },
  sendvolume: {
    getStaticData: () =>
      Array(12)
        .fill(null)
        .map((_, i) => ({
          invoice_month: dayjs().subtract(i, 'month').format('YYYY-MM'),
          volume: getRandomNumber(100_000, 400_000)
        })),
    key: 'invoice_month',
    regex: /^\/v2\/emma\/client\/\d+\/send-volume/
  },
  subscribers: {
    getDefaults: () => ({
      datejoin: getDateString(undefined, { iso8601: true })
    }),
    key: 'member_id',
    regex: /^\/v2\/emma\/client\/\d+\/membertable\/\d+\/member/,
    update: (rows) =>
      refreshDates(rows, ['created_at', 'datejoin', 'updated_at'])
  },
  templatepreview: {
    generateEntry: (template) => ({
      from: 'Demo',
      html: template.body_long,
      message_id: template.message_id,
      subject: template.subject,
      text: template.body_text
    }),
    getStaticData: async (r) => {
      const serviceConfig = mockedServices.templatepreview
      const url = new URL(r.url)
      const match = url.pathname.match(serviceConfig.regex)
      const id = Number(match[1])
      const template = await getEndpointDataRow('templates', id)
      return serviceConfig.generateEntry(template)
    },
    key: 'message_id',
    regex: /^\/v2\/emma\/client\/\d+\/message\/(\d+)\/render/,
    skipParams: true,
    transformRow: async (body, _id, url) => {
      if (body) return body
      const serviceConfig = mockedServices.templatepreview
      const match = url.pathname.match(serviceConfig.regex)
      const id = Number(match[1])
      const template = await getEndpointDataRow('templates', id)
      return serviceConfig.generateEntry(template)
    }
  },
  templates: {
    getDefaults: (payload) => ({
      campaign: [],
      is_tracked: true,
      manager_id: JSON.parse(atob(localStorage.loginToken.split('.')[1])).sub
        .id,
      member_table_id: 1,
      message_type: 3,
      nb_locked: 0,
      screenshot: [{ url_large: screenshot }],
      url: [
        ...payload.body_long.matchAll(/href(?!\s+)?=(?!\s+)?['"](.*?)['"]/gm)
      ].map((m, i) => ({
        id: m.index,
        is_active: true,
        name: `URL${i + 1}`,
        url: m[1]
      })),
      user: [getUser()]
    }),
    includes: ['campaign', 'emmajob', 'screenshot', 'url', 'user'],
    key: 'message_id',
    regex: /^\/emma\/message/
  },
  testsubscribers: {
    getDefaults: () => ({ active: true }),
    key: 'member_id',
    regex: /^\/emma\/testaddress/,
    update: (rows) =>
      refreshDates(rows, ['created_at', 'datejoin', 'updated_at'])
  },
  transactionaldoireports: {
    getStaticData: (r) => {
      const params = new URLSearchParams(r.url)
      const endDate = dayjs(params.get('end_date') ?? undefined)

      let startDate = params.has('start_date')
        ? dayjs(params.get('start_date'))
        : endDate.subtract(7, 'day')

      let entries = getRandomNumber(200, 250)
      const data = []

      while (!startDate.isAfter(endDate)) {
        const imported = getRandomNumber(160, 180)
        entries = entries + getRandomNumber(-10, 10)
        data.push({
          date: startDate.format('YYYY-MM-DD'),
          entries,
          imported
        })
        startDate = startDate.add(1, 'day')
      }

      return data
    },
    key: 'date',
    regex: /^\/v2\/emma\/client\/\d+\/membertable\/\d+\/doi-report/,
    transformIndex: async (rows, _id, url) => {
      const numDays = dayjs(url.searchParams.get('end_date'))
        .add(1, 'day')
        .diff(url.searchParams.get('start_date'), 'day')

      if (rows.length !== numDays) {
        const serviceName = 'transactionaldoireports'
        const serviceConfig = mockedServices[serviceName]

        rows = await serviceConfig.getStaticData({ url: url.toString() })
        await setEndpointData(serviceName, serviceConfig, rows)
      }

      return rows.reduce((acc, { date, ...data }) => {
        acc[date] = data
        return acc
      }, {})
    }
  },
  transactionalreports: {
    generateEntry: (date) => ({
      date,
      sent: 0,
      opened: getRandomNumber(140, 160),
      proxy_opened: getRandomNumber(4, 8),
      clicked: 0,
      unsubscribed: getRandomNumber(0, 15),
      complained: getRandomNumber(1, 5),
      soft_bounced: 0,
      hard_bounced: 0,
      other_bounced: 0
    }),
    getStaticData: (r) => {
      const url = new URL(r.url)
      const endDate = dayjs(url.searchParams.get('end_date') ?? undefined)

      let startDate = url.searchParams.has('start_date')
        ? dayjs(url.searchParams.get('start_date'))
        : endDate.subtract(7, 'day')

      const data = []
      while (!startDate.isAfter(endDate)) {
        data.push(
          mockedServices.transactionalreports.generateEntry(
            startDate.format('YYYY-MM-DD')
          )
        )
        startDate = startDate.add(1, 'day')
      }

      return data
    },
    key: 'date',
    regex: /^\/transactional-report/,
    transformRow: async (_rows, _id, url) => {
      const endDate = dayjs(url.searchParams.get('end_date') ?? undefined)
      let startDate = url.searchParams.has('start_date')
        ? dayjs(url.searchParams.get('start_date'))
        : endDate.subtract(7, 'day')

      const data = []
      const rows = await getEndpointData('transactionalreports')
      const newRows = []

      const serviceName = 'transactionalreports'
      const serviceConfig = mockedServices[serviceName]

      while (!startDate.isAfter(endDate)) {
        const d = startDate.format('YYYY-MM-DD')

        let row = rows.find((r) => r.date === d)
        if (!row) {
          row = serviceConfig.generateEntry(d)
          newRows.push(row)
        }

        data.push(row)
        startDate = startDate.add(1, 'day')
      }

      if (newRows.length > 0) {
        await setEndpointData(serviceName, serviceConfig, newRows)
      }

      return data.reduce((acc, { date, ...data }) => {
        acc[date] = data
        return acc
      }, {})
    }
  },
  usersettings: {
    key: 'user_id',
    regex: /^\/user\/(\d+)\/settings/,
    skipParams: true
  },
  user: {
    getDefaults: (row) => ({
      clients: [{ client_id, client_name: 'EMMA Demo' }],
      isDemo: true,
      password: undefined,
      roles: [{ id: row.role, name: row.role }],
      settings: { client_id }
    }),
    includes: ['clients', 'settings'],
    key: 'id',
    regex: /^\/user/,
    update: (rows) => {
      const { sub } = getUserTokenPayload()
      return rows.filter((u) => u.id === sub.id || u.isDemo)
    }
  },
  dashboardview_report: {
    generateEntry: (startDate, endDate, reports) => {
      const cols = [
        'nb_click',
        'nb_complaint',
        'nb_hardbounce',
        'nb_open',
        'nb_otherbounce',
        'nb_send',
        'nb_softbounce',
        'nb_unsubscribe'
      ]
      const data = {}
      const now = dayjs()

      let currentDate = startDate.clone()
      while (currentDate.isBefore(endDate)) {
        const d = currentDate.format('YYYY-MM-DD')
        data[d] = {
          nb_campaigns: reports.filter(
            (r) => r.startdate.substring(0, 10) === d
          ).length,
          nb_campaigns_queued: 0,
          nb_delivered: 0,
          nb_send_queued: 0
        }
        for (const col of cols) {
          data[d][col] = 0
        }
        currentDate = currentDate.add(1, 'day')
      }

      for (const r of reports) {
        const daysRunning = now.diff(dayjs(r.startdate), 'day')
        const startDate = dayjs(r.startdate)

        if (daysRunning < 1) {
          const d = startDate.format('YYYY-MM-DD')
          if (!data[d]) continue
          for (const key in data[d]) {
            data[d][key] += r[key]
          }
          data[d].nb_delivered = getDelivered(data[d])
          continue
        }

        for (let i = 0; i < daysRunning; i++) {
          const d = startDate.add(i, 'day').format('YYYY-MM-DD')
          if (!data[d]) continue
          for (const key of cols) {
            data[d][key] += Math.ceil((r[key] * 0.85) ** (1 - i / 10))
          }
          data[d].nb_delivered = getDelivered(data[d])
        }
      }

      return data
    },
    getStaticData: () => [],
    key: 'id',
    regex: /^\/view\/dashboard\/report/,
    transformIndex: async (_rows, _id, url) => {
      const endDate = dayjs(url.searchParams.get('end_date')).add(1, 'day')
      const startDate = dayjs(url.searchParams.get('start_date'))

      const reports = await getEndpointData('reports').catch(() =>
        api.reports
          .findAll$()
          .pipe(map((r) => r.data))
          .toPromise()
      )

      return mockedServices.dashboardview_report.generateEntry(
        startDate,
        endDate,
        reports
      )
    }
  }
}

export default mockedServices
