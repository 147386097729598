import i18n from '../../i18n'
import { addModals, removeModals } from '../../hooks/useModals'
import { sortAlphabetically } from '../../utils/data'
import { getDateString } from '../../utils/date'
import api from '../index'
import { flattenItem } from '../brm'
import mockedServices from './mocked-services'

export const allowedEndpoints = [
  /\/auth\/login/,
  /\/v2\/emma\/client\/\d+\/bav-confirm/,
  /\/v2\/emma\/client\/\d+\/message:generate-subject/,
  /\/validator/
]

export const client_id = 20607

const deleteEndpointDataRow = (serviceName, id) =>
  getDb().then((db) =>
    new Promise((resolve, reject) => {
      const tx = db.transaction(serviceName, 'readwrite')
      tx.onerror = (e) => reject(e.target.error)

      const store = tx.objectStore(serviceName)
      const request = store.delete(id)
      request.onerror = (e) => reject(e.target.error)
      request.onsuccess = (e) => resolve(e.target.result)
    }).finally(() => db.close())
  )

const extractBody = async (req) => {
  const decoderStream = new TextDecoderStream()
  const decompressionStream = new DecompressionStream('gzip')

  let data = null
  let str = ''
  const writableStream = new WritableStream({
    write(chunk) {
      str += chunk
    },
    close() {
      data = JSON.parse(str)
    }
  })

  if (req.body) {
    await req.body
      .pipeThrough(decompressionStream)
      .pipeThrough(decoderStream)
      .pipeTo(writableStream)
  }

  return data
}

const filterRows =
  ({ search = [''], ...filters }) =>
  (row) =>
    Object.values(row)
      .map((val) => (val ?? '').toString().toLowerCase())
      .some((val) => search.every((s) => val.includes(s))) &&
    Object.entries(filters).every(([key, vals]) =>
      vals.some((v) => (row[key] ?? '').toString() === (v ?? '').toString())
    )

const getData = async (req, origin, pathname) => {
  const [serviceName, serviceConfig] = getServiceConfig(pathname)
  const u = new URL(`${origin}${pathname}`)
  const match = u.pathname.match(serviceConfig.regex)

  let data = []
  try {
    if (match?.[1]) {
      data = await getEndpointDataRow(serviceName, Number(match[1]))
    } else {
      data = await getEndpointData(serviceName)
    }
  } catch {
    if (!serviceConfig.skipParams) {
      u.searchParams.set('limit', 0)

      for (const [key, val] of new URL(req.url).searchParams) {
        if (key === 'end_date' || key === 'start_date') {
          u.searchParams.set(key, val)
        }

        if (key === 'enddate' || key === 'startdate') {
          u.searchParams.set(key, val)
        }

        if (key === 'explicitly_included[]' || key === 'included[]') {
          u.searchParams.append(key, val)
        }
      }
    }

    if (serviceConfig.includes) {
      for (const inc of serviceConfig.includes) {
        u.searchParams.append('explicitly_included[]', inc)
      }
    }

    const r = new Request(u.toString(), {
      headers: {
        accept: req.headers.get('accept'),
        authorization: req.headers.get('authorization')
      }
    })

    if (
      u.searchParams.has('included[]') ||
      u.searchParams.has('explicitly_included[]')
    ) {
      r.headers.set('Accept', 'application/vnd.api+json')
    }

    if (serviceConfig.getStaticData) {
      const json = await serviceConfig.getStaticData(r)
      if (Array.isArray(json) || json.data) {
        data.push(
          ...(json.data ?? json).map((row) => flattenItem(row, json.included))
        )
      } else {
        data.push(json)
      }
    } else {
      const json = await window.windowFetch(r).then((res) => res.json())

      if (json.pagination) {
        const numPages = Math.ceil(json.pagination.total / 50)
        await Promise.all(
          Array(numPages)
            .fill(null)
            .map((_, i) => {
              const u2 = new URL(r.url)
              u2.searchParams.set('limit', 50)
              u2.searchParams.set('offset', i * 50)

              const r2 = new Request(u2)
              for (const [key, val] of r.headers) {
                r2.headers.set(key, val)
              }

              return window
                .windowFetch(r2)
                .then((res) => res.json())
                .then((json) =>
                  data.push(
                    ...json.data.map((row) => flattenItem(row, json.included))
                  )
                )
            })
        )
      } else {
        data.push(json)
      }
    }

    if (serviceConfig.update) {
      data = await serviceConfig.update(data)
    }
  }

  setEndpointData(serviceName, serviceConfig, data)
  return data
}

const getDataRow = async (req, origin, pathname, id) => {
  const [serviceName, serviceConfig] = getServiceConfig(pathname)

  let body
  try {
    body = await getEndpointDataRow(serviceName, id)
  } catch {
    const data = await getData(req, origin, pathname)
    await setEndpointData(serviceName, serviceConfig, data)
    body = data.find((row) => row[serviceConfig.key] === id)
  }

  return body
}

const getDb = () =>
  new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(
      'demo',
      Object.keys(mockedServices).length
    )

    openRequest.onerror = (error) => {
      reject(error)
    }

    openRequest.onsuccess = (event) => {
      const db = event.target.result

      db.onerror = (error) => {
        console.error('Demo db error', error)
      }

      resolve(db)
    }

    openRequest.onupgradeneeded = (event) => {
      const db = event.target.result

      db.onerror = (error) => {
        reject(error)
      }

      for (const name of Object.keys(mockedServices)) {
        if (!db.objectStoreNames.contains(name)) {
          db.createObjectStore(name)
        }
      }
    }
  })

export const getEndpointData = (serviceName) =>
  getDb().then((db) =>
    new Promise((resolve, reject) => {
      const tx = db.transaction(serviceName, 'readwrite')
      tx.onerror = (e) => reject(e.target.error)

      const store = tx.objectStore(serviceName)
      const request = store.getAll()
      request.onerror = (e) => reject(e.target.error)
      request.onsuccess = (e) => {
        const { result } = e.target
        if (!result?.length) {
          reject()
        } else {
          resolve(result)
        }
      }
    }).finally(() => db.close())
  )

export const getEndpointDataRow = (serviceName, id) =>
  getDb().then((db) =>
    new Promise((resolve, reject) => {
      const tx = db.transaction(serviceName, 'readwrite')
      tx.onerror = (e) => reject(e.target.error)

      const store = tx.objectStore(serviceName)
      const request = store.get(id)
      request.onerror = (e) => reject(e.target.error)
      request.onsuccess = (e) => {
        const { result } = e.target
        if (!result) {
          reject(new Error('Not found'))
        } else {
          resolve(result)
        }
      }
    }).finally(() => db.close())
  )

const getEndpointNextKey = (serviceName, key) =>
  getEndpointData(serviceName)
    .then(
      (data) =>
        data.reduce((acc, row) => (acc > row[key] ? acc : row[key]), 0) + 1
    )
    .catch(() => 1)

const getServiceConfig = (pathname) => {
  const [serviceName, serviceConfig] = Object.entries(mockedServices).find(
    ([, c]) => c.regex.test(pathname)
  )

  if (serviceConfig.alias) {
    return [serviceConfig.alias, mockedServices[serviceConfig.alias]]
  }

  return [serviceName, serviceConfig]
}

export const initDemo = async () => {
  try {
    const campaigns = await getEndpointData('campaignsv2')
    if (campaigns.length === 0) {
      throw new Error()
    }
  } catch {
    const modalIds = addModals([
      {
        actions: [],
        body: i18n.t('users.loading_demo'),
        noClose: true,
        title: i18n.t('users.demo')
      }
    ])

    await api.campaignsv2.find$(undefined, { client_id }).toPromise()

    removeModals(modalIds)
  }
}

export const localFetch = async (req) => {
  const url = new URL(req.url)
  let pathname = url.pathname

  let id
  if (/\d+$/.test(url.pathname)) {
    const parts = url.pathname.split('/')
    id = Number(parts.pop())
    pathname = parts.join('/')
  }

  const [serviceName, serviceConfig] = getServiceConfig(pathname)
  let method = req.method.toUpperCase()

  const idMatch = pathname.match(serviceConfig.regex)
  if (!id && idMatch?.[1]) {
    id = Number(idMatch[1])
  }

  if (serviceName === 'usersettings' && method === 'POST') {
    method = 'PATCH'
  }

  let body
  if (id) {
    body = await getDataRow(req, url.origin, pathname, id)

    if (serviceConfig.transformRow) {
      body = await serviceConfig.transformRow(body, id, url)
    }

    switch (method) {
      case 'DELETE':
        await deleteEndpointDataRow(serviceName, id)
        break

      case 'PATCH': {
        const payload = await extractBody(req)
        for (const [key, val] of Object.entries(payload)) {
          body[key] = val
        }
        body.updated_at = getDateString(undefined, { iso8601: true })
        await setEndpointData(serviceName, serviceConfig, [body])
        break
      }

      case 'PUT': {
        body = await extractBody(req)
        body.updated_at = getDateString(undefined, { iso8601: true })
        await setEndpointData(serviceName, serviceConfig, [body])
        break
      }
    }
  } else if (method === 'POST') {
    body = await extractBody(req)

    body[serviceConfig.key] = await getEndpointNextKey(
      serviceName,
      serviceConfig.key
    )
    body.created_at = getDateString(undefined, { iso8601: true })
    body.updated_at = getDateString(undefined, { iso8601: true })

    for (const [key, val] of Object.entries(
      (await serviceConfig.getDefaults?.(body)) || {}
    )) {
      body[key] = val
    }

    await setEndpointData(serviceName, serviceConfig, [body])
  } else {
    const data = await getData(req, url.origin, pathname)

    if (serviceConfig.transformIndex) {
      body = await serviceConfig.transformIndex(data, id, url)
    } else {
      const ignore = [
        'explicitly_included[]',
        'includes[]',
        'startdateAfter',
        'startdateBefore'
      ]
      const params = {}
      for (let [key, val] of url.searchParams.entries()) {
        if (ignore.includes(key)) continue

        key = key.endsWith('[]') ? key.substring(0, key.length - 2) : key
        val = (val.includes(',') ? val.split(',') : [val]).map((v) =>
          /\d+/.test(v) ? Number(v) : v === '\u0000' ? null : v
        )
        params[key] ??= []
        params[key].push(...val)
      }

      let {
        all_membertables,
        desc,
        limit = 10,
        offset = 0,
        orderby = serviceConfig.key,
        ...filters
      } = params
      desc = desc?.includes('true')
      limit = Number(limit)
      offset = Number(offset)

      const filtered = data
        .filter(filterRows(filters))
        .sort(sortAlphabetically({ desc, key: orderby }))

      let paginated = filtered.slice(offset, offset + limit)
      if (serviceConfig.transformRows) {
        paginated = await serviceConfig.transformRows(paginated)
      }

      body = {
        data: paginated,
        pagination: {
          limit,
          offset,
          total: filtered.length
        }
      }
    }
  }

  return new Response(JSON.stringify(body))
}

export const mockedMethods = ['DELETE', 'PATCH', 'POST', 'PUT']

export const mockEndpoint = (url) => {
  const u = new URL(url)
  let pathname = u.pathname

  if (/\d+$/.test(u.pathname)) {
    const parts = u.pathname.split('/')
    parts.pop()
    pathname = parts.join('/')
  }

  if (pathname.endsWith('/')) {
    pathname = pathname.substring(0, pathname.length - 1)
  }

  return Object.values(mockedServices).some((c) => c.regex.test(pathname))
}

export const resetDemo = (callback) =>
  new Promise((resolve, reject) => {
    const req = indexedDB.deleteDatabase('demo')
    req.onblocked = (event) => reject(event)
    req.onerror = (event) => reject(event)
    req.onsuccess = () => resolve(callback?.())
  })

export const setEndpointData = (serviceName, serviceConfig, data) =>
  getDb().then((db) =>
    Promise.all(
      data.map(
        (row) =>
          new Promise((resolve, reject) => {
            const tx = db.transaction(serviceName, 'readwrite')
            tx.oncomplete = () => resolve()
            tx.onerror = (e) => reject(e.target.error)

            const store = tx.objectStore(serviceName)
            const request = store.put(row.rows ?? row, row[serviceConfig.key])
            request.onerror = (e) => reject(e.target.error)
          })
      )
    ).finally(() => db.close())
  )
